import Vue from "vue";
import Vuex from "vuex";
import i18n from "@/i18n";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        language: "fr",
        showSuccess: false,
        badge: ""
    },
    mutations: {
        setShowSuccess(_state, _show) {
            _state.showSuccess = _show;
        },
        setBadge(_state, _badge) {
            _state.badge = _badge;
        },
    },
    actions: {
        setShowSuccess(_context, _show) {
            _context.commit("setShowSuccess", _show);
        },
        setBadge(_context, _badge) {
            _context.commit("setBadge", _badge);
        },
    },
    modules: {},
});
