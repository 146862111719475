
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component({
    components: {
    },
})
export default class PartyPopup extends Vue {
    private m_seed = "";
    private m_showPopup = false;

    private mounted(){
        this.generateRandom();
    }

    private copyLink(){
        let lUrl = window.location.origin;
        lUrl += "/?seed=" + this.m_seed
        navigator.clipboard.writeText(lUrl);
        this.m_showPopup = true;
        setTimeout(() => {
            this.m_showPopup = false
        }, 1000)
    }

    private generateRandom(){
        let lResult = '';
        const lCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const lCcharactersLength = lCharacters.length;
        for (let i=0; i < 12; i++) {
            lResult += lCharacters.charAt(Math.floor(Math.random() * lCcharactersLength));
        }
        this.m_seed = lResult;
    }
}
