
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import HistoryElem from "./HistoryElem.vue"
import ilesName from "../assets/ilesNames.json"
import * as L from 'leaflet';

@Component({
    components: {
    },
})
export default class Success extends Vue {
    @Prop() historyRef;
    @Prop() badgeList;
    @Prop() maxStreak;

    private m_gameCount = 0;
    private m_streakGameCount = 0;
    private m_perfectGameCount = 0;
    private m_nullGamesCount = 0;
    private m_closeGameCount = 0;
    private m_lessThan10Meter = 0;
    private m_0Meter = 0;

    private m_isInWilds = 0;
    private m_isInShores = 0;
    private m_isInRoar = 0;
    private m_isInAncient = 0;


    private m_badgesList;
    private m_badgesShow;


    private getPercentage(_elem){
        let lPercentage = this[_elem['unlock']]() * 100 / _elem['goal'];
        if (lPercentage > 100){
            lPercentage = 100;
        }
        return lPercentage + "%"
    }

    private getCurrentValue(_elem){
        let lValue = this[_elem['unlock']]();
        if (lValue > _elem['goal'])
        {
            lValue = _elem['goal']
        }
        return lValue;
    }

    private hasUnlock(_value) {
        if (this.m_badgesList != undefined)
        return this.m_badgesList.includes(_value)
        else return false
    }

    private getGameCount() {
        return this.m_gameCount;
    }

    private getRowGameCount(){
        return this.m_streakGameCount;
    }

    private getPerfectGamesCount(){
        return this.m_perfectGameCount;
    }

    private getNullGamesCount(){
        return this.m_nullGamesCount;
    }

    private getCloseGamesCount(){
        return this.m_closeGameCount;
    }

    private getLessThan10Meter(){
        return this.m_lessThan10Meter;
    }

    private get0Meter(){
        return this.m_0Meter;
    }

    private getWild(){
        return this.m_isInWilds;
    }

    private getRoar(){
        return this.m_isInRoar;
    }

    private getPlenty(){
        return this.m_isInShores;
    }

    private getAncient(){
        return this.m_isInAncient;
    }

    private mounted(){
        //localStorage.removeItem("badges")
        //localStorage.removeItem("badges_show")
        this.m_badgesList = localStorage.getItem('badges');
        if (localStorage['badges'] == null || localStorage['badges'] == undefined) {
            localStorage.setItem('badges', JSON.stringify([]))
            this.m_badgesList = []
        } else{
            this.m_badgesList = JSON.parse(this.m_badgesList)
        }
        
        this.m_badgesShow = localStorage.getItem('badges_show');
        if (localStorage['badges_show'] == null || localStorage['badges_show'] == undefined) {
            localStorage.setItem('badges_show', JSON.stringify([]))
            this.m_badgesShow = []
        }

        this.checkData();
        this.badgeList.forEach((_badge) => {
            if(this[_badge['unlock']]() >= _badge.goal){
                this.m_badgesList.push(_badge['value'])
            }
        })
        this.checkBadge();
    }

    private checkBadge() {
        for (let i=0; i < this.m_badgesList.length; i++)
        {
            const lBadge = this.m_badgesList[i]
            if (!this.m_badgesShow.includes(lBadge)){
                this.$store.dispatch("setBadge", lBadge)
                break;
            }
        }
    }

    private countValuesInArray(_array, _value) {
        let lCount = 0;
        _array.forEach(_elem => {
            if (_elem == _value){
                lCount++;
            }
        })
        return lCount;
    }

    private countValuesLessThanInArray(_array, _value) {
        let lCount = 0;
        _array.forEach(_elem => {
            if (_elem >= 0 && _elem < _value){
                lCount++;
            }
        })
        return lCount;
    }

    private getStreak(){
        return this.maxStreak;
    }

    private isMarkerInsidePolygon(_coord, poly) {
        const polyPoints = poly.getLatLngs()[0];       
        const x = _coord[0];
        const y = _coord[1];

        let inside = false;
        for (let i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
            const xi = polyPoints[i].lat, yi = polyPoints[i].lng;
            const xj = polyPoints[j].lat, yj = polyPoints[j].lng;

            const intersect = ((yi > y) != (yj > y))
                && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect){
                inside = !inside;
            } 
        }

        return inside;
    }

    private checkData() {
        const lScores :number[] = [];
        const lDistances :number[] = [];
        const lPerfectCoord: any[] = [];
        
        let lMaxStreak = 0;
        let lCurrentStreak = 1;
        let lPreviousDate : Date = new Date();
        this.m_isInShores = 0;
        this.m_isInRoar = 0;
        this.m_isInAncient = 0;
        this.m_isInWilds = 0;


        for (let i = 0; i < Object.keys(this.historyRef['menu']['$refs']).length; i++) {
            const lKey = Object.keys(this.historyRef['menu']['$refs'])[i];
            const lElem = this.historyRef['menu']['$refs'][lKey] as HistoryElem;

            lScores.push(lElem[0].getAllPoints)
            lDistances.push(lElem[0].getAllDistance)

            const lDateKey = lKey.replace("elem-", "").split("-");
            const lDay = lDateKey[0]
            const lMonth = lDateKey[1]
            const lYear = lDateKey[2]
            const lCurrentDate = new Date(parseInt(lYear),parseInt(lMonth)-1,parseInt(lDay))
            

            if (i != 0) {
                const lDayDiff = (lPreviousDate.getTime() - lCurrentDate.getTime())/(1000*60*60*24);

                if (lDayDiff == 1) {
                    lCurrentStreak++;
                }
                else{
                    if (lCurrentStreak > lMaxStreak){
                        lMaxStreak = lCurrentStreak;
                    }
                    lCurrentStreak = 1;
                }
            }
            lPreviousDate = lCurrentDate;
            if (lElem[0].getDistanceArray().includes(0)){
                this.m_0Meter++;
            }
            lElem[0].getPerfect().forEach(_elem => {
                lPerfectCoord.push(_elem);
            });
        }
        if (lCurrentStreak > lMaxStreak){
            lMaxStreak = lCurrentStreak;
        }

        this.m_gameCount = Object.keys(this.historyRef['menu']['$refs']).length;
        this.m_perfectGameCount = this.countValuesInArray(lScores, 15000);
        this.m_nullGamesCount = this.countValuesInArray(lScores, 0);
        this.m_closeGameCount = this.countValuesInArray(lScores, 14999);
        this.m_lessThan10Meter = this.countValuesLessThanInArray(lDistances, 10);
        this.m_streakGameCount = lMaxStreak;

        const lShores = L.polygon(ilesName["region"]["shores"]);
        const lWilds = L.polygon(ilesName["region"]["wilds"]);
        const lAncient = L.polygon(ilesName["region"]["ancient"]);
        const lRoar = L.polygon(ilesName["region"]["roar"]);

        lPerfectCoord.forEach(_coord => {
            if (this.isMarkerInsidePolygon(_coord, lShores)){
                this.m_isInShores++;
            }
            else if (this.isMarkerInsidePolygon(_coord, lWilds)){
                this.m_isInWilds++;
            }
            else if (this.isMarkerInsidePolygon(_coord, lAncient)){
                this.m_isInAncient++;
            }
            else if (this.isMarkerInsidePolygon(_coord, lRoar)){
                this.m_isInRoar++;
            }
        })
    }

}
