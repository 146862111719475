import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import VueRouter from "vue-router";
import Vue2TouchEvents from 'vue2-touch-events'
import i18n from "./i18n";
import "vue-resize/dist/vue-resize.css";
import VueResize from "vue-resize";

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(VueResize);
Vue.use(Vue2TouchEvents)

const router = new VueRouter({
    mode: "history"
});

new Vue({
    store,
    router,
    i18n,
    render: (h) => h(App),
}).$mount("#app");