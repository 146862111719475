import { render, staticRenderFns } from "./ManageHistory.vue?vue&type=template&id=27a5c18a&scoped=true&"
import script from "./ManageHistory.vue?vue&type=script&lang=ts&"
export * from "./ManageHistory.vue?vue&type=script&lang=ts&"
import style0 from "./ManageHistory.vue?vue&type=style&index=0&id=27a5c18a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27a5c18a",
  null
  
)

export default component.exports