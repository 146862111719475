
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component({
    components: {
    },
})
export default class HistoryElem extends Vue {

    @Prop() userCoord;
    @Prop() answerCoord;
    @Prop() date;

    private k_minDistance = 15;
    private k_maxDistance = 4000;
    private m_currentIndex = 0;

    public get getAllPoints(){
        return this.getTotalPoints();
    }

    public get getAllDistance() {
        let lDistance = 0;
        if (this.userCoord.length < this.answerCoord.length){
            return -1
        }
        for (let i = 0; i < this.userCoord.length; i++){
            lDistance += this.getDistance(i)
        }
        return lDistance;
    }

    public getTotalPoints(){
        let lScore = 0;
        for (let i = 0; i < this.userCoord.length; i++) {
            lScore += this.getPoints(i);
        }
        return lScore;
    }

    private getPoints(_index) {
        let lPoints = 0;
        if (this.getScore(_index) > 0){
            lPoints = (this.getScore(_index) * 5000) / this.k_maxDistance
        }
        return Math.round(lPoints);
    }

    public getDistanceArray() {
        const lArray :number[] = []
        for (let i = 0; i < this.userCoord.length; i++) {
            lArray.push(this.getDistance(i))
        }
        return lArray;
    }

    public getScore(_index){
        let lScore = this.k_maxDistance - this.getDistance(_index) + this.k_minDistance;
        if (lScore < 0){
            lScore = 0;
        }
        if (lScore > this.k_maxDistance){
            lScore = this.k_maxDistance;
        }
        return lScore;
    }

    private getDistance(_index) {
        const pointX = this.userCoord[_index]['coord']['lng']
        const pointY = this.userCoord[_index]['coord']['lat'] * -1;
        const responseX = this.answerCoord[_index][1];
        const responseY = this.answerCoord[_index][0] * -1;
        const y = responseX - pointX;
        const x = responseY - pointY;

        let lDistance = Math.sqrt(x * x + y * y);
        lDistance = Math.round(((lDistance * 617.3) / 34));
        return lDistance;
    }

    private getPerfect(){
        const lResult :any[] = [];
        for (let i = 0; i < this.userCoord.length; i++) {
            if (this.getPoints(i) == 5000){
                lResult.push(this.answerCoord[i])
            }
        }
        return lResult
    }

    private next() {
        this.m_currentIndex++;
        if (this.m_currentIndex > this.userCoord.length - 1)
        {
            this.m_currentIndex = 0;
        }
    }

    private previous() {
        this.m_currentIndex--;
        if (this.m_currentIndex < 0)
        {
            this.m_currentIndex = this.userCoord.length - 1;
        }
    }
}
