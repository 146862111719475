
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import HistoryElem from "./HistoryElem.vue"
import data from "../assets/data2.json"

@Component({
    components: {
        HistoryElem
    },
})
export default class Menu extends Vue {

    private m_showMenu = false;

    private getAnswerCoord(_key){
        return data[_key]
    }

    private get getHistory() {
        const lHistory = localStorage.getItem('history')
        if (lHistory != null) {
            return JSON.parse(lHistory)
        }
        else {
            return {}
        }
    }
    
    private onlyNumbers(str) {
        return /^[0-9-]+$/.test(str);
    }

    private get getKeys() {
        const lObject = Object.fromEntries(Object.entries(this.getHistory).filter(([key]) => 
            this.onlyNumbers(key)
        ));
        const lKeys = Object.keys(lObject).reverse();
        return lKeys;
    }

    private mouseLeave(){
        this.m_showMenu = false;
    }

    private mouseEnter(){
        this.m_showMenu = true;
    }

    private reverseMenu() {
        this.m_showMenu = !this.m_showMenu;
    }
}
