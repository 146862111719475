
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component({
    components: {
    },
})
export default class ManageHistory extends Vue {

    private m_history = ""
    private m_validData = false;

    private isBase64(_str) {
        if (_str ==='' || _str.trim() ===''){ return false; }
        try {
            return btoa(atob(_str)) == _str;
        } catch (err) {
            return false;
        }
    }

    private isJson(_str) {
        try {
            JSON.parse(_str);
        } catch (e) {
            return false;
        }
        return true;
    }

    private downloadHistory() {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(btoa(localStorage['history'])));
        element.setAttribute('download', 'history');
        element.style.display = 'history';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    private setHistory(){
        localStorage['history'] = (atob(this.m_history));
        this.m_history = "";
        this.m_validData = false;
        location.reload();
    }

    private checkData(){
        if (this.isBase64(this.m_history)) {
            const lData = atob(this.m_history);
            if (this.isJson(lData)) {
                this.m_validData = true;
            }
            else {
                this.m_validData = false;
            }
        } else {
            this.m_validData = false;
        }
    } 
}
